<template>
    <div>
        <span class="student_answer_checked">
            <span v-if="question_types.question_type.short_title != 'ROP'">
                <li v-for="(item, index) in this.answers_r" :key="index" class="my-3 flex align-center" v-bind:class="{ active: disable ? item.is_correct_answer :  '' }">
                    <md-checkbox 
                        v-if="question_types.question_type.short_title == 'MCQMA'"
                        disabled :disable="disable"  :value="item.is_correct_answer" v-model="disable" :checked="item.is_correct_answer"> 
                    </md-checkbox>
                    <md-radio 
                        v-if="question_types.question_type.short_title != 'MCQMA'"
                        disabled :disable="disable"  :value="item.is_correct_answer" v-model="disable" :checked="item.is_correct_answer"> 
                    </md-radio>
                    <Content :text="item.title" class="w-74" v-bind:class="[answer[index].is_wrong === 1 ? 'active_red' : 'active_green']"/>
                </li>
            </span>
        </span>
        <span class="text-base" v-if="question_types.question_type.short_title == 'ROP'">
            <div class="copy mt-4" style="margin-top:0px">
                <div class="item">
                    <div class="md-drop-item pl-1 pr-1" v-if="activeValue == 'active_green'">
                        <div v-for="(item,index) in question" :key="index">
                            <div  class="draggable-item md-drop-item m-2 p-2 flex align-center">
                            {{item.value}}
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </span> 
        <span class="text-base" v-if="question_types.question_type.short_title == 'ROP'">
            <div class="copy mt-4" style="margin-top:0px">
                <div class="item pair_list">
                    <ul class="md-drop-item st_item_rop pl-1 pr-1" v-if="activeValue == 'active_red'">
                        <li v-for="(item,index) in this.answers" :key="index">
                                <div class="draggable-item p-2 flex align-center" v-bind:class="[item.is_wrong === 1 ? 'wrong_answer' : 'right_answer']">
                                    <span v-if="item.is_wrong === 1" class="info_button" @click="scrollRightAnswer">
                                        <InfoIcon/>
                                       <md-tooltip md-direction="top" class="wrong_pair"><h3 class="red">Wrong Pair</h3></md-tooltip>  
                                    </span>
                                    <span v-if="item.is_wrong === 0" class="info_button">
                                        <RightIcon/>
                                       <md-tooltip md-direction="top" class="right_pair"><h3>Right Pair</h3></md-tooltip>  
                                    </span>
                                    {{item.value}}
                                </div>  
                        </li>
                    </ul>
                </div>
            </div>
        </span> 
    </div>
</template>
<script>
import Content from "@/components/atom/Content";
import InfoIcon from '../../../../assets/svg/info.svg';
import RightIcon from '../../../../assets/svg/correct.svg';
export default {
    components: {
      Content,
      InfoIcon,
      RightIcon
    },
    props:{
        answer: {
           default: true 
        },
        activeValue: {
           default: true 
        },
        question_types:{
            default: true
        },
        question:{
            default: true
        },
        rop_student_pairs:{
            default: true
        }
    },
    data() {
        return {
            question_type: [],
            disable: 1,
            answers:[],
            answers_r:[],
            questions:[],
            answerPair: []
        }
    },
    mounted(){
        setTimeout(() => {
          this.Sort()
        },200);
        
    },
    methods:{
        Sort(){
            if(this.answer !== null){
              this.answers_r =  this.answer.sort((a, b) => a.new_serial - b.new_serial)
            }
            
            this.answers =  this.rop_student_pairs.sort(function(a, b) {
               return a.pair_count - b.pair_count;
            });
            this.questions =  this.question.sort(function(a, b) {
               return a.serial_no - b.serial_no;
            });
            
           
            // this.questions =  this.question.sort((a, b) => a.serial_no - b.serial_no)
        },
        scrollRightAnswer(){
           var element = document.querySelector("#scroll");
            // scroll to element
            element.scrollIntoView({ behavior: 'smooth', block: 'end'}); 
        }
    },
    
}
</script>
<style>
.right_answer{
    background-color: #00640059;
}
.wrong_answer{
    background-color: rgb(172 17 56 / 15%);
    padding-right: 34px !important;
}
.fair_gap{
    height: 10px;
    height: 10px;
    position: relative;
    display: block;
}
.st_item_rop{
    padding: 12px 12px 0px 12px !important;
}
.wrong_pair{
    background: #e00303ad !important;
    box-shadow: 1px 4px 5px 1px #858585c4 !important;
}
.right_pair{
    background: #069a33d4 !important;
    box-shadow: 1px 4px 5px 1px #858585c4 !important;
}
</style>