<template>
    <div class="evaluation_prograss pt-4 pb-4">
        <Alert classObject="rounded-lg p-2">
            <div class="flex justify-between align-center pb-6">
                <div class="flex align-center">
                    <h2 style="margin-top: -3px;">Your Pitch & Voice / pitch Analysis</h2>
                    <span style="background: rgb(164, 15, 188)" class="text-white back-blue br-6 pl-1 pr-1 ml-2">Analysis</span>
                </div>
                <div class="flex">
                    <span class="font-bold br-6 p-1 pl-2 pr-2 mr-2 mr-d dot_green flex align-center">
                        <span class="grid">Perfect</span>
                    </span>
                    <span class="font-bold br-6 p-1 pl-2 pr-2 mr-2 mr-d dot_orange flex align-center">
                        <span class="grid">High</span>
                    </span>
                    <span class="font-bold br-6 p-1 mr-2 pl-2 pr-2 mr-d dot_red flex align-center">
                        <span class="grid">Low</span>
                    </span>
                    <span class="font-bold br-6 p-1 mr-2 pl-2 pr-2 mr-d dot_gray flex align-center">
                        <span class="grid">Pause</span>
                        
                    </span>
                </div>
            </div>
            <div class="b-d p-1 b-d-c br-6" style="height:300px">
                <LineChart :chartData="words" :chartOptions="chartOptions"/>
            </div>
        </Alert>
    </div>
</template>

<script>
import { Alert} from '@/components';
import LineChart from './chart/Linechart.vue';
import { mapActions,mapGetters} from 'vuex';
export default {
    components : {
        Alert,
        LineChart
    },
    data() {
        return {
          amount: 65,
           Offset: '',
           words: '',
           word: '',
           chartOptions: '',
        }
    },
    computed: { 
    ...mapGetters({
      getResults: "result/getResults",
    }),
    },
    methods:{
        ...mapActions({
            actShowResult: "result/actShowResult",
            actResultList: "result/actResultList"
        }),
    },
    created() {
      this.answer_sheet_id = localStorage.getItem('answer_sheet_id');
      console.log('sss',this.answer_sheet_id)
      this.actShowResult(this.answer_sheet_id)
          .then(res => 
            res.answer_sheet_details.evaluation_details.map(item  => {
                item.NBest.map(item  => {
                    const word = item.Words.map(item  => {
                        if(item.PronunciationAssessment.ErrorType != "Omission"){
                          return item.Word.replace(/p>/g, '')
                        }
                    })
                    const data = item.Words.map(item  => {
                       return item.PronunciationAssessment.AccuracyScore
                    })
                    const color = item.Words.map(item  => {
                       return item.PronunciationAssessment.AccuracyScore 
                    })
                    const colors = color.filter(item  => {
                       return item  !== undefined
                    })
                    const col = colors.map(item  => {
                       return item >= 80 ? 'green' :  item >= 60 ? 'orange' : item >= 40 ? 'red' : '#dbdbdb'
                    })
                    
                    console.log('color', col )
                    this.words = {
                        labels:word.filter((items)  => {return items !== undefined  }),
                        datasets: [
                            {  
                                data: data.filter(item => !isNaN(item)),
                                label: "",
                                color: "#fff0",
                                borderColor: "#8b8b8b",
                                backgroundColor: '#fff0',
                                fill: true,
                                borderDash: [4,5], // try [5, 15] for instance
                                borderDashOffset: 0.0,
                                borderWidth: 1,
                                lineTension: 0, 
                                pointRadius: 3.8,
                                pointBackgroundColor: "#8b8b8b",
                            },
                        ],
                    },
                    this.chartOptions= {
                        scales: {
                            x: {
                                ticks: {
                                    color:  col,
                                    beginAtZero: true,
                                    minRotation: 90,
                                    font: {
                                        size: 15,
                                    }
                                },
                                grid: {
                                   display: false,
                                    zeroLineColor: "transparent",
                                },
                            },
                            y: {
                                ticks: {
                                color:  '#fff0',
                                beginAtZero: true,
                                minRotation: 90,
                                fontSize: 16,
                                },
                                grid: {
                                   display: false,
                                    zeroLineColor: "transparent",
                                },
                            },
                        },
                        legend: {
                            display: false,
                            color: "red"
                        },
                        responsive: true,
                        maintainAspectRatio: false,
                    }
                })
               
            })  
      )
    },

}
</script>

