<template>
    <div class="question_list">
            <div class="text-base line-height-27 reading mt-6 mb-0">
                <!-- <span style="font-weight:600" class="text-uppercase" ></span> -->
                <span v-if="question.question_type.short_title == 'MCQMA' || question.question_type.short_title == 'MCQSA' || question.question_type.short_title == 'ROP'" class="ck-content question__t" v-html="question.title">
                </span>
                
                <DropDown class="reading_drop_down" v-if="question.question_type.short_title == 'FGDD'" :case_item="question"/>
                <span v-if="question.question_type.short_title == 'FGDND'" class="ck-content question__t reading_drop_dwon drop_drop_question" v-html="question.title">
                </span> 
            </div>
            <div class="text-base line-height-27">
                <span class="student_answer_checked" v-if="question.question_type.short_title == 'FGDND'">
                    <span class="flex justify-center" id="drop_answer">
                        <li v-for="(item, index) in question.question_options" :key="index" class="my-3 flex align-center">
                            <Content :text="item.title"/>
                        </li>
                    </span>
                </span>
                <span class="student_answer_checked" v-if="question.question_type.short_title == 'MCQMA' || question.question_type.short_title == 'MCQSA' || question.question_type.short_title == 'ROP'">
                    <span>
                        <li v-for="(item, index) in question.question_options" :key="index" class="my-3 flex align-center">
                            <md-checkbox 
                                v-if="question.question_type.short_title == 'MCQMA'"
                                v-model="disabled" disabled> 
                            </md-checkbox>
                           
                            <md-radio 
                                v-if="question.question_type.short_title != 'MCQMA'"
                                v-model="disabled" disabled> 
                            </md-radio>
                            <Content :text="item.title" class="w-74"/>
                        </li>
                    </span>
                </span>
                <span class="text-base" v-if="question.question_type.short_title == 'ROP'">
                    <div class="copy mt-4" style="margin-top:0px">
                        <div class="item">
                            <div class="md-drop-item pl-1 pr-1">
                                 <h4 class="text-uppercase text-victoria pt-2 pl-2 pb-1">Source</h4>
                                <div v-for="(item,index) in this.source" :key="index">
                                    <div class="draggable-item md-drop-item m-2 p-2 flex align-center">
                                    {{item.title}}
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="item">
                            <div class="md-drop-item pl-1 pr-1" >
                                <h4 class="text-uppercase text-victoria pt-2 pl-2 pb-1">Target</h4>
                                <div v-for="(item,index) in reorder_correct_pairs" :key="index">
                                    <div class="draggable-item md-drop-item m-2 p-2 flex align-center">
                                    {{item.value}}
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </span>
            </div>  
    </div>
</template>
<script>
import Content from "@/components/atom/Content";
import DropDown from "./question/DropDown/dropDown_right_answer.vue";
export default {
    components: {
      Content,
      DropDown,
    },
    props:{
        question: {
           default: true 
        },
        answer_option: {
           default: true 
        },
    },
    data() {
        return {
            question_type: [],
            disabled: 'disabled',
            reorder_incorrect_pairs: [],
            reorder_correct_pairs: [],
            source:[]
        }
    },
    mounted(){
        this.Sort();
        console.log('answer_option',this.answer_option);
        this.source =  this.answer_option.answer_options.sort(function(a, b) {
            return a.new_serial  - b.new_serial ;
        });
    },
    methods:{
        Sort(){
            this.reorder_incorrect_pairs =  this.question.reorder_incorrect_pairs.sort(function(a, b) {
               return a.new_serial  - b.new_serial ;
            });
            this.reorder_correct_pairs =  this.question.reorder_correct_pairs.sort(function(a, b) {
               return a.serial_no  - b.serial_no ;
            });
            //this.reorder_incorrect_pairs =  this.question.reorder_incorrect_pairs.sort((a, b) => a.serial_no - b.serial_no)
            //this.reorder_correct_pairs =  this.question.reorder_correct_pairs.sort((a, b) => a.serial_no - b.serial_no)
        }
   },
    
}
</script>
<style scoped>
.copy {
  display: flex;
  gap: 1rem;
}
.item {
  flex: 1;
}
#drop_answer{
    border-bottom: 1px solid #bab8b8;
}

#drop_answer li .my-2{
   color: #fff !important;
   background: #691ee2 !important;
   padding: 2px 10px;
}

#drop_answer li{
   margin: 2px;
   padding: 2px 5px;
}

</style>
