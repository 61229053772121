<template>
    <div class="evaluation_prograss pt-4">
        <Alert classObject="rounded-lg p-2">
            <div class="flex justify-between align-center">
                <div class="flex align-center">
                    <h2 style="margin-top: -3px;">Your Answer</h2>
                    <span style="background: rgb(164, 15, 188)" class="text-white br-6 pl-1 pr-1 ml-2">Recording</span>
                </div>
            </div>
            <div v-if="answer_sheet.question.question_type.section == 'SPEAKING'">
              <Audio :src="answer_sheet.file_path"/> 
            </div>
            <div v-if="answer_sheet.question.question_type.section == 'READING'">
                 <AnswerSection :answer="answer_sheet" :question="answer_sheet.question"/>
            </div>
        </Alert>
    </div>
</template>

<script>
import { Alert} from '@/components';
import Audio from "./speaking/audio.vue";
import AnswerSection from "./reading/answerSection.vue";
export default {
    components : {
        Alert,
        Audio,
        AnswerSection,
    },
    props:{
       answer_sheet: {
           default: true 
       }
    },
    data() {
        return {
          amount: 65,
          record:"record",
          file_path: localStorage.getItem('file_path'),
        }
    },
    watch:{
      answer_sheet: {
        handler(val){
         // answer_sheet
         location.reload
         this.question = val.file_path;
         localStorage.setItem('file_path',this.question)
        },
      }  
    },
}
</script>

