<template>
    <div class="evaluation_prograss pt-4">
        <Alert background-color="#efe7fa" classObject="rounded-lg p-2">
            <div class="flex md-prograss__space" style="margin-left: -60px">
                <PrograssRound :label="'Content'" :percentage="answer_sheet.content_percentage"/>
                <PrograssRound :label="'Grammer'" :percentage="answer_sheet.grammar_percentage"/>
                <PrograssRound :label="'Vocabulary'" :percentage="answer_sheet.vocabulary_percentage"/>
                <PrograssRound v-if="answer_sheet.question.question_type.short_title !='SWT'" :label="'Spelling'" :percentage="answer_sheet.spelling_percentage"/>
            </div>
        </Alert>
    </div>
</template>

<script>
import { Alert} from '@/components';
import {PrograssRound} from '@/components';
export default {
    components : {
        Alert,
        PrograssRound
    },
    props:{
       answer_sheet: {
           default: true 
       }
    },
    data() {
        return {
          question: '',
          question_type: '',
        }
    },
    watch:{
      answer_sheet: {
        handler(val){
        // answer_sheet
        this.question = val.question
        this.question_type = this.question.question_type
        },
      }  
    },

}
</script>
<style scoped>
.md-progress-spinner {
  margin: 24px;
      -ms-transform: rotate(20deg); /* IE 9 */
    -webkit-transform: rotate(20deg); /* Safari 3-8 */
    transform: rotate(-54deg);
}

</style>
