<template>
    <div class="evaluation_prograss pt-4">
        <Alert background-color="#efe7fa" classObject="rounded-lg p-2">
            <div class="flex md-prograss__space">
                <div v-if="question_type.slug !='answer-short-question' " style="position:relative" class="flex align-center justify-center">
                   <md-progress-spinner class="md-prograss_border" md-mode="determinate" :md-value="parseInt(answer_sheet.fluency_percentage * 100) / 100"> 
                    <circle cx="100" cy="100" r="50" fill="#000" />
                    </md-progress-spinner>
                    <span class="md-prograss_circle font-bold"> {{parseInt(answer_sheet.fluency_percentage * 100) / 100}}%</span>
                    <b class="md-prograss_text">Fluency</b>
                </div>
                <div v-if="question_type.slug !='answer-short-question' " style="position:relative" class="flex align-center justify-center">
                    <md-progress-spinner class="md-prograss_border" md-mode="determinate" :md-value="parseInt(answer_sheet.pronunciation_percentage * 100) / 100"> 
                    <circle cx="100" cy="100" r="50" fill="#000" />
                    </md-progress-spinner>
                    <span class="md-prograss_circle font-bold">{{parseInt(answer_sheet.pronunciation_percentage * 100) / 100}}%</span>
                    <b class="md-prograss_text">Pronunciation</b>
                </div>
                <div v-if="question_type.slug =='answer-short-question' " style="position:relative" class="flex align-center justify-center">
                    <md-progress-spinner class="md-prograss_border" md-mode="determinate" :md-value="parseInt(answer_sheet.form_percentage * 100) / 100"> 
                    <circle cx="100" cy="100" r="50" fill="#000" />
                    </md-progress-spinner>
                    <span class="md-prograss_circle font-bold">{{parseInt(answer_sheet.content_percentage * 100) / 100}}</span>
                    <b class="md-prograss_text">Vocabulary</b>
                </div>
                <div v-if="question_type.slug !='answer-short-question' " style="position:relative" class="flex align-center justify-center">
                    <md-progress-spinner class="md-prograss_border" md-mode="determinate" :md-value="parseInt(answer_sheet.content_percentage * 100) / 100"> 
                    <circle cx="100" cy="100" r="50" fill="#000" />
                    </md-progress-spinner>
                    <span class="md-prograss_circle font-bold">{{parseInt(answer_sheet.content_percentage * 100) / 100}}</span>
                    <b class="md-prograss_text">Content</b>
                </div>

                <div v-if="question_type.short_title =='ES'" style="position:relative" class="flex align-center justify-center">
                    <md-progress-spinner class="md-prograss_border" md-mode="determinate" :md-value="parseInt(answer_sheet.form_percentage * 100) / 100"> 
                    <circle cx="100" cy="100" r="50" fill="#000" />
                    </md-progress-spinner>
                    <span class="md-prograss_circle font-bold">{{parseInt(answer_sheet.content_percentage * 100) / 100}}</span>
                    <b class="md-prograss_text">Vocabulary</b>
                </div>
                <div v-if="question_type.short_title =='ES'" style="position:relative" class="flex align-center justify-center">
                    <md-progress-spinner class="md-prograss_border" md-mode="determinate" :md-value="parseInt(answer_sheet.content_percentage * 100) / 100"> 
                    <circle cx="100" cy="100" r="50" fill="#000" />
                    </md-progress-spinner>
                    <span class="md-prograss_circle font-bold">{{parseInt(answer_sheet.content_percentage * 100) / 100}}</span>
                    <b class="md-prograss_text">Content</b>
                </div>
            </div>
        </Alert>
    </div>
</template>

<script>
import { Alert} from '@/components';
export default {
    components : {
        Alert
    },
    props:{
       answer_sheet: {
           default: true 
       }
    },
    data() {
        return {
          question: '',
          question_type: '',
        }
    },
    watch:{
      answer_sheet: {
        handler(val){
        // answer_sheet
        this.question = val.question
        this.question_type = this.question.question_type
        },
      }  
    },

}
</script>
<style scoped>
.md-progress-spinner {
  margin: 24px;
      -ms-transform: rotate(20deg); /* IE 9 */
    -webkit-transform: rotate(20deg); /* Safari 3-8 */
    transform: rotate(-54deg);
}

</style>
