<template>
    <div class="question_list">
        <div class="text-base line-height-27 reading mt-0 mb-0">
            <span v-if="question.question_type.short_title != 'FG'"> 
                <span v-if="question.title != null" class="ck-content m-0" v-html="question.title">
                </span>
            </span>
        </div>
        <div class="text-base line-height-27">
            <span class="student_answer_checked">
                <span v-if="question.question_type.short_title != 'FG'">
                    <span v-if="question.question_type.short_title != 'HIW'">
                        <li v-for="(item, index) in question.question_options" :key="index" class="my-0 flex align-center">
                            <md-checkbox 
                                v-if="question.question_type.short_title == 'MCQMA'"
                                v-model="disabled" disabled> 
                            </md-checkbox>
                            <md-radio 
                                v-if="question.question_type.short_title != 'MCQMA'"
                                v-model="disabled" disabled> 
                            </md-radio>
                            <Content :contentWidth ="width" :text="item.title" class="w-100"/>
                        </li>
                    </span>
                </span>
                <span class="question_fig" v-if="question.question_type.short_title == 'FG'">
                   <FilInGap :case_item="question" :answer_drop="question.question_options"/>
                </span>
            </span>
        </div>  
    </div>
</template>
<script>
import Content from "@/components/atom/Content";
import FilInGap from "./question/filInGap.vue";
export default {
    components: {
      Content,
      FilInGap
    },
    props:{
        question: {
           default: true 
        },
        answer_option: {
           default: true 
        },
    },
    data() {
        return {
            question_type: [],
            disabled: 'disabled',
            width:'w-100'
        }
    },  
}
</script>

