<template>
    <div>
        <div class="md-question__radio md-sl__font_600">
            <md-content id="handleItem" class="p-3 br-3 md-sl__font_600 md-sl__x1 mb-4 m-0"  style="background-color: #fff0;">
                <div v-html="this.highlightWords" class="answers" id="fill_in_gap">
                </div>
            </md-content>
        </div>
    </div>
</template>

<script>
// import DrapFiled from "./drapFiled.vue";
export default {
    name: 'DropDown',
    data() {
      return {
          highlightWords: [],
      }
    },
    props: {
        case_item: {
           default: () => true
        },
    },
    mounted(){
        this.highlightWord();
    },
    methods:{
        highlightWord(){
            let text = this.case_item.title.replace(/(<p[^>]+>|<p>|<\/p>)/g, "");
            console.log(text)
            this.highlightWords = text.replace(/ (\/)?\[[^\]]*]/g, '<$1span class="dropdown"$2><$1span class="dropdowns"$2></$1span></$1span>');
        },
    }
}
</script>

<style scoped>
.answers .target{
    border: 1.5px solid #cfcaca;
    min-width: 110px;
    margin: 0 3px;
    text-align: center;
    min-height: 30px;
}
select option:disabled {
    color: #000;
    font-weight: bold;
}
.answers select{
    border: 0px solid #ced4da;
    color: #15be15;
    pointer-events: none;
    cursor: default;
    -webkit-appearance: none;
    -moz-appearance: none;
    text-indent: 1px;
    text-overflow: '';
}

.answer_body{
    display: none;
}
.back-light_gray{
   background: #c6d0d9
}
.back-gray-light{
   background: #e6e4e5
}
.target::before{
    content: '1';
    position: absolute;
}
.target span{
    background: whitesmoke;
    z-index: 1;
}
.answers .wrong_answer{
    color: red;
}
</style>