<template>
    <div>
        <Question :answer_sheet="answer_sheet"/>
        <Answer :answer_sheet="answer_sheet"/>
        <SimpleAnswer :answer_sheet="answer_sheet" :question_type="question_type" id="scrollError"/> 
    </div>
</template>


<script>
import { 
  SimpleAnswer
  } from "../index";
import { 
  Question,
  Answer,
  } from "../index";
export default {
    components: {
        Question,
        Answer,
        SimpleAnswer,
    },
    props:{
        answer_sheet: {
            default: true 
        }
    },
};
</script>