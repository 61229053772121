<template>
    <div class="evaluation_prograss pt-4">
        <Alert classObject="rounded-lg p-2">
            <div class="flex justify-between align-center">
                <div class="flex align-center">
                    <h2 style="margin-top: -3px;">Question</h2>
                    <span class="text-white back-blue br-6 pl-1 pr-1 ml-2">{{question.q_id}}</span>
                    <span class="text-white back-black br-6 pl-1 pr-1 ml-2">Attempt {{answer_sheet.attempt}}</span>
                    <span class="back-gray br-6 pl-1 pr-1 ml-2">{{question.created_at}}</span>
                </div>
                <div class="flex">
                    <span class="text-white back-blue br-6 p-1 pl-2 pr-2 mr-2">New</span>
                    <span class="text-white back-green br-6 p-1 mr-2 pl-2 pr-2">PTE Practice</span>
                    <span class="text-white back-orange br-6 p-1 mr-2 pl-2 pr-2">Exam Q</span>
                </div>
            </div>
            <div v-if="answer_sheet.question.question_type.section == 'SPEAKING' || answer_sheet.question.question_type.section == 'LISTENING'">
                <span v-if="question_type.slug !='describe-image'">
                   <Audio :src="answer_sheet.question.file_path"/>
                </span>
                <div v-if="question_type.slug =='describe-image'" class="pt-6 pb-6" style="border-bottom: 1px solid #c0c0c0">
                   <img class="br-9" :src="answer_sheet.question.file_path"/>
                </div>
            </div>
            <div v-if="answer_sheet.question.question_type.section == 'LISTENING'">
                 <ListeningQuestionList :question="answer_sheet.question" :answer_option="answer_sheet"/>
            </div>
            <div v-if="answer_sheet.question.question_type.section == 'READING'">
                 <QuestionSection :question="answer_sheet.question" :answer_option="answer_sheet"/>
            </div>
            <div v-if="answer_sheet.question.question_type.section == 'WRITING'">
                 <QuestionList :question="answer_sheet.question" :answer_option="answer_sheet"/>
            </div>
        </Alert>
    </div>
</template>

<script>
import { Alert} from '@/components';
import Audio from "./speaking/audio.vue";
import QuestionSection from "./reading/questionSection.vue";
import QuestionList from "./writing/Question.vue";
import ListeningQuestionList from "./listening/questionSection.vue";
export default {
    components : {
        Alert,
       Audio,
       QuestionSection,
       QuestionList,
       ListeningQuestionList
    },
    props:{
       answer_sheet: {
           default: true 
       }
    },
    data() {
        return {
          question: [],
          question_type: [],
          file_path: ''
        }
    },
    watch:{
      answer_sheet: {
        handler(val){
        // answer_sheet
        this.question = val.question
        this.question_type = this.question.question_type
        },
      }  
    },

}
</script>

