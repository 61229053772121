<template>
    <div class="evaluation_prograss pt-2">
        <Alert classObject="rounded-lg">
            <div class="flex justify-between align-center">
                <div class="flex align-center">
                    <h2 style="margin-top: -3px;">Your Answer</h2>
                </div>
            </div> 
            <FilInGap class="b-d-c b-d mt-4 mb-4 br-3" :case_item="answer_sheet.question" :answer_drop="answer" :active_green="active_green" v-if="answer_sheet.question.question_type.short_title == 'FG'"/>
            <HIWAnswer class="b-d-c b-d mt-4 mb-4 br-3" :case_item="answer_sheet.question" :answer_drop="answer" :active_green="active_green" v-if="answer_sheet.question.question_type.short_title == 'HIW'"/>
            <span v-if="answer_sheet.question.question_type.short_title != 'FG'">
                <span v-if="answer_sheet.question.question_type.short_title != 'HIW'">
                    <AnswerList :answer_sheet="answer_sheet.answer_sheet_details" :question="answer_sheet.question" :answer="answer" class="rounded-lg p-2 p-3 mt-4 br-3 md-sl__font_600 md-sl__x1 mb-4 m-0 b-d b-d-c"/>
                </span>
            </span>
        </Alert>
    </div>
</template>
<script>
import { Alert} from '@/components';
import AnswerList from "./answerList.vue";
import FilInGap from "./question/filInGap.vue";
import HIWAnswer from "./question/HIWAnswer.vue";
export default {
    components: {
      Alert,
      AnswerList,
      FilInGap,
      HIWAnswer
    },
    props:{
        answer_sheet: {
           default: true 
        },
        answer:{
            default: true
        },
    },
    data() {
        return {
           disable: 1,
            answers:[],
            answers_short:[],
            questions:[],
            active_red:'active_red',
            active_green:'active_green'
        }
    },  
    mounted(){
        setTimeout(() => {
          this.Sort()
        },600); 
    },
    methods:{
        Sort(){
            if(this.answer !== null){
              this.answers_short =  this.answer.sort((a, b) => a.new_serial - b.new_serial)
            }
        }
    }
}
</script>
