<template>
    <div class="index evaluation">
        <PrograssEvaluate :answer_sheet="answer_sheet" :question_type="question_type"/>
        <Question :answer_sheet="answer_sheet" :question_type="question_type"/>
        <Answer :answer_sheet="answer_sheet" :question_type="question_type"/>
        <SimpleAnswer :answer_sheet="answer_sheet" :question_type="question_type"/>
        <Recognition :answer_sheet="answer_sheet" :question_type="question_type"/>
        <PitchAnalysis :answer_sheet="answer_sheet" :question_type="question_type"/>
    </div>
</template>


<script>
import { 
  PrograssEvaluate,
  Question,
  Answer,
  SimpleAnswer,
  Recognition,
  PitchAnalysis,
  } from "../index";
export default {
    components: {
        PrograssEvaluate,
        Question,
        Answer,
        SimpleAnswer,
        Recognition,
        PitchAnalysis,
    },
    props:{
        answer_sheet: {
            default: true 
        },
        question_type:{
           default: true 
        }
    },
};
</script>