<template>
    <div>
        <div class="md-question__radio md-sl__font_600">
            <md-content class="pb-3 mt-4 br-3 md-sl__font_600 md-sl__x1 mb-4 m-0 b-d-c" style="border-bottom: 1px solid #bebebe;">
                <div class="question" >
                    <h3 class="mb-2" v-html="answer_sheet.question.index"></h3>
                    <p v-html="answer_sheet.question.title"></p>
                </div>
            </md-content>
        </div>
    </div>
</template>

<script>
export default {
    name: 'Question',
    data() {
      return {
          question: '',
          title: ''
      }
    },
    props: {
        answer_sheet: {
           default: () => true
        },
    },
    mounted(){
       
    },
    methods:{
       
    }
}
</script>

