<template>
    <div class="evaluation_prograss">
        <Alert classObject="rounded-lg p-2">
            <span id="scroll"></span>
            <div class="flex justify-between align-center">
                <div class="flex align-center pb-2">
                    <h2 class="pb-3">All Score</h2>
                </div>
            </div>
            <div class="br-6 b-d p-2 b-d-c">
                <Alert background-color="#6616d0" classObject="rounded-lg p-2">
                    <div class="md-layout md-gutter text-white">
                        <span class="display-content">
                          <div class="md-layout-item md-size-15 flex align-center justify-center p-3" ><h3>Section</h3></div>
                          <div class="md-layout-item md-size-15 flex align-center justify-center p-3"><h3>Your Score</h3></div>
                          <div class="md-layout-item md-size-20 flex align-center justify-center p-3"><h3>PTE Scoring</h3></div>
                          <div class="md-layout-item flex align-center justify-center p-3"><h3>Your Feedback</h3></div>
                        </span>
                    </div>
                </Alert>
                <div class="md-body p-2">
                      <div class="md-layout md-gutter md-header b-d-t">
                        <span class="display-content">
                          <div class="md-layout-item md-size-15 p-3 font-bold flex align-center justify-center text-blue">Form</div>
                          <div class="md-layout-item md-size-15 p-3 font-bold flex align-center justify-center" >{{parseInt(answer_sheet.form_percentage * 100) / 100}}</div>
                          <div class="md-layout-item md-size-20 p-3 font-bold flex align-center justify-center">{{this.scorefluency}}</div>
                        </span>
                        <div class="md-layout-item p-3">
                            <ul class="pb-2">
                                <span v-for="(filter_item, index) in filter_list" :key="index">
                                    <li v-if="index < limit_by_flu" >{{ filter_item }}</li>
                                </span>
                            </ul>
                            <a href="javascript:void(0)" class="text-white back-blue br-6 pl-1 pr-1 cursor" style="padding-bottom: 0.15rem;" @click="fluency_toggle(default_limit, filter_list.length)">{{ limit_by_flu===5?'Know More': 'Know Less'}}</a>
                        </div>
                      </div> 
                  <span class="display-content">
                    <div class="md-layout md-gutter md-header b-d-t">
                          <div class="md-layout-item md-size-15 p-3 font-bold flex align-center justify-center text-blue">Content</div>
                          <div class="md-layout-item md-size-15 p-3 font-bold flex align-center justify-center">{{parseInt(answer_sheet.content_percentage * 100) / 100}}</div>
                          <div class="md-layout-item md-size-20 p-3 font-bold flex align-center justify-center">{{this.scorevocabulary}}</div>
                        <div class="md-layout-item p-3">
                            <ul class="pb-2">
                                <span v-for="(filter_item, index) in filter_list" :key="index">
                                    <li v-if="index < limit_by_voca" >{{ filter_item }}</li>
                                </span>
                            </ul>
                            <a href="javascript:void(0)" class="text-white back-blue br-6 pl-1 pr-1 cursor" style="padding-bottom: 0.15rem;" @click="vocabulary_toggle(default_limit, filter_list.length)">{{ limit_by_voca===5?'Know More': 'Know Less'}}</a>
                        </div>
                    </div> 
                    <div v-if="answer_sheet.question.question_type.short_title =='ES'" class="md-layout md-gutter md-header b-d-t">
                          <div class="md-layout-item md-size-15 p-3 font-bold flex align-center justify-center text-blue">Development structure and coherence</div>
                          <div class="md-layout-item md-size-15 p-3 font-bold flex align-center justify-center">{{parseInt(answer_sheet.development_structure_coherence_percentage * 100) / 100}}</div>
                          <div class="md-layout-item md-size-20 p-3 font-bold flex align-center justify-center">{{this.scorecontent}}</div>
                        <div class="md-layout-item p-3">
                            <ul class="pb-3">
                                <li>lorem ipsum dolor sit amet,consectetuer adipiscing elit</li>
                                <li>lorem ipsum dolor sit amet,consectetuer adipiscing elit</li>
                                <li>lorem ipsum dolor sit amet,consectetuer adipiscing elit</li>
                                <li>lorem ipsum dolor sit amet,consectetuer adipiscing elit</li>
                                <li>lorem ipsum dolor sit amet,consectetuer adipiscing elit</li>
                            </ul>
                             <span class="text-white back-blue br-6 pl-1 pr-1 cursor" style="padding-bottom: 0.15rem;">Know More</span>
                        </div>
                    </div> 
                    <div v-if="answer_sheet.question.question_type.short_title =='ES'" class="md-layout md-gutter md-header b-d-t">
                          <div class="md-layout-item md-size-15 p-3 font-bold flex align-center justify-center text-blue">General Linguistics range</div>
                          <div class="md-layout-item md-size-15 p-3 font-bold flex align-center justify-center">{{parseInt(answer_sheet.general_linguistics_percentage * 100) / 100}}</div>
                          <div class="md-layout-item md-size-20 p-3 font-bold flex align-center justify-center">{{this.scorepronunciation}}</div>
                        <div class="md-layout-item p-3">
                            <ul class="pb-2">
                                <span v-for="(filter_item, index) in filter_list" :key="index">
                                    <li v-if="index < limit_by_pro" >{{ filter_item }}</li>
                                </span>
                            </ul>
                            <a href="javascript:void(0)" class="text-white back-blue br-6 pl-1 pr-1 cursor" style="padding-bottom: 0.15rem;" @click="pro_toggle(default_limit, filter_list.length)">{{ limit_by_pro===5?'Know More': 'Know Less'}}</a>
                        </div>
                    </div> 
                    <div class="md-layout md-gutter md-header b-d-t">
                          <div class="md-layout-item md-size-15 p-3 font-bold flex align-center justify-center text-blue">Grammar</div>
                          <span v-if="!this.error_grammar_icon" class="info_button" @click="scrollRightAnswer">
                            <InfoIcon/>
                            <md-tooltip md-direction="top" class="wrong_pair"><h3 class="red">Click to check blue underlined parts to find your grammar mistakes.</h3></md-tooltip>  
                          </span>
                          <div class="md-layout-item md-size-15 p-3 font-bold flex align-center justify-center">{{parseInt(answer_sheet.grammar_percentage * 100) / 100}}</div>
                          <div class="md-layout-item md-size-20 p-3 font-bold flex align-center justify-center">{{this.scorecontent}}</div>
                        <div class="md-layout-item p-3">
                            <ul class="pb-3">
                                <li>lorem ipsum dolor sit amet,consectetuer adipiscing elit</li>
                                <li>lorem ipsum dolor sit amet,consectetuer adipiscing elit</li>
                                <li>lorem ipsum dolor sit amet,consectetuer adipiscing elit</li>
                                <li>lorem ipsum dolor sit amet,consectetuer adipiscing elit</li>
                                <li>lorem ipsum dolor sit amet,consectetuer adipiscing elit</li>
                            </ul>
                             <span class="text-white back-blue br-6 pl-1 pr-1 cursor" style="padding-bottom: 0.15rem;">Know More</span>
                        </div>
                    </div> 
                    <div class="md-layout md-gutter md-header b-d-t">
                          <div class="md-layout-item md-size-15 p-3 font-bold flex align-center justify-center text-blue">Vocabulary</div>
                          <div class="md-layout-item md-size-15 p-3 font-bold flex align-center justify-center">{{parseInt(answer_sheet.vocabulary_percentage * 100) / 100}}</div>
                          <div class="md-layout-item md-size-20 p-3 font-bold flex align-center justify-center">{{this.scorepronunciation}}</div>
                        <div class="md-layout-item p-3">
                            <ul class="pb-2">
                                <span v-for="(filter_item, index) in filter_list" :key="index">
                                    <li v-if="index < limit_by_pro" >{{ filter_item }}</li>
                                </span>
                            </ul>
                            <a href="javascript:void(0)" class="text-white back-blue br-6 pl-1 pr-1 cursor" style="padding-bottom: 0.15rem;">Know More</a>
                        </div>
                    </div> 
                    <div v-if="answer_sheet.question.question_type.short_title =='ES'" class="md-layout md-gutter md-header b-d-t">
                          <div class="md-layout-item md-size-15 p-3 font-bold flex align-center justify-center text-blue">Spelling</div>
                          <span v-if="!this.error_spelling_icon" class="info_button" @click="scrollRightAnswer">
                            <InfoIcon/>
                            <md-tooltip md-direction="top" class="wrong_pair"><h3 class="red">Click to check red text parts to find your spelling mistakes</h3></md-tooltip>  
                          </span>
                          <div class="md-layout-item md-size-15 p-3 font-bold flex align-center justify-center">{{parseInt(answer_sheet.spelling_percentage * 100) / 100}}</div>
                          <div class="md-layout-item md-size-20 p-3 font-bold flex align-center justify-center">{{this.scorecontent}}</div>
                        <div class="md-layout-item p-3">
                            <ul class="pb-3">
                                <li>lorem ipsum dolor sit amet,consectetuer adipiscing elit</li>
                                <li>lorem ipsum dolor sit amet,consectetuer adipiscing elit</li>
                                <li>lorem ipsum dolor sit amet,consectetuer adipiscing elit</li>
                                <li>lorem ipsum dolor sit amet,consectetuer adipiscing elit</li>
                                <li>lorem ipsum dolor sit amet,consectetuer adipiscing elit</li>
                            </ul>
                             <span class="text-white back-blue br-6 pl-1 pr-1 cursor" style="padding-bottom: 0.15rem;">Know More</span>
                        </div>
                    </div> 
                  </span>
                </div>
                <Alert classObject="rounded-lg br-6 b-d p-2 b-d-c">
                    <div class="md-layout md-gutter">
                        <div class="md-layout-item flex align-center p-3 b-d-r b-d-c">
                            <div>
                              <span class="flex lign-center pt-1">
                                <h3>Overall Score of <b class="pr-2">Writing</b></h3>
                                <div class="">
                                    <span class="br-6 back-blue text-white pl-1 pr-1">{{parseInt(answer_sheet.writing_percentage * 100) / 100}}</span>
                                </div>
                              </span>
                              <span class="flex lign-center pt-1" style="float: right" v-if="answer_sheet.question.question_type.short_title =='SWT'">
                                <h3><b class="pr-2">Reading</b></h3>
                                <div class="">
                                    <span class="br-6 back-blue text-white pl-1 pr-1">{{parseInt(answer_sheet.reading_percentage * 100) / 100}}</span>
                                </div>
                              </span>
                              <span class="flex lign-center pt-1" style="float: right" v-if="answer_sheet.question.question_type.short_title =='SST'">
                                <h3><b class="pr-2">Listening</b></h3>
                                <div class="">
                                    <span class="br-6 back-blue text-white pl-1 pr-1">{{parseInt(answer_sheet.listening_percentage * 100) / 100}}</span>
                                </div>
                              </span>
                            </div>
                        </div>
                        <div class="md-layout-item flex align-center justify-right p-3">
                            <div >
                                <h2 class="text-blue p-1" style="text-align: right;">Your Duration</h2>
                                <h3 class="p-1">28 secs <span class="font-normal"> out of 40 secs</span></h3>
                            </div>
                        </div>
                    </div>
                </Alert>
                <Alert classObject="rounded-lg p-2 flex justify-center ev-button">
                    <md-button class="rounded br-12 m-5 b-d p-3 font-bold text-green m-0 text-uppercase" style="height:auto;width:260px">
                      <span class="mr-2"><Headphone/></span>Request for Manual Check
                    </md-button>
                    <md-button class="back-green br-12 rounded m-5 b-d p-3 text-white md-dense m-0 text-uppercase" style="height:auto;width:260px">
                      <span class="mr-2"><Message/></span>Discuss on Forum
                    </md-button>
                </Alert>
            </div>
            <Alert classObject="rounded-lg p-2 pt-8 flex justify-right ev-button">
                <BottomSection :answer_sheet="answer_sheet"/>
            </Alert>
        </Alert>
    </div>
</template>

<script>
import { Alert} from '@/components';
import Headphone from '../../../../assets/svg/headphone.svg';
import Message from '../../../../assets/svg/message.svg';
import InfoIcon from '../../../../assets/svg/info.svg';
import BottomSection from '../evaluationBottom.vue'
export default {
    components : {
        Alert,
        Headphone,
        Message,
        BottomSection,
        InfoIcon
    },
    props:{
       answer_sheet: {
           default: true,
           //bottomHide: ''
           //bottomHide: localStorage.getItem('answer_bottom_hide')
       }
    },
    data() {
        return {
          amount: 65,
          error_grammar_icon: false,
          error_spelling_icon: false,
          filter_list: [
            "lorem ipsum dolor sit amet,consectetuer adipiscing elit",
            "lorem ipsum dolor sit amet,consectetuer adipiscing elit",
            "lorem ipsum dolor sit amet,consectetuer adipiscing elit lorem ipsum dolor sit amet,consectetuer adipiscing elit",
            "lorem ipsum dolor sit amet,consectetuer adipiscing elit",
            "lorem ipsum dolor sit amet,consectetuer adipiscing elit",
            "lorem ipsum dolor sit amet,consectetuer adipiscing elit lorem ipsum dolor sit amet,consectetuer adipiscing elit",
            "lorem ipsum dolor sit amet,consectetuer adipiscing elit"
            ],
            default_limit: 5,
            limit_by_flu: 5,
            limit_by_voca: 5,
            limit_by_pro: 5,
            question: '',
            question_type: '',
            scorefluency: '',
            scorevocabulary: '',
            scorecontent: '',
            scorepronunciation: ''
        }
    },
    watch:{
      answer_sheet: {
        handler(val){
          // answer_sheet
          this.question = val.question;
          this.question_type = this.question.question_type;
          let x = parseInt(val.form_percentage * 100) / 100 ;
          let y = parseInt(val.content_percentage * 100) / 100 ;
          let z = parseInt(val.content_percentage * 100) / 100 ;
          let v = parseInt(val.pronunciation_percentage * 100) / 100 ;
          
          if( x > 80){
            this.scorevocabulary = "Advance";
          } 
          else if(x > 70){
            this.scorevocabulary= "Good"; 
          } 
          else if(x > 60){
            this.scorevocabulary = "Avarage";
          }
          else if(x > 10){
            this.scorevocabulary = "Poor";
          }
          else if(x < 10){
            this.scorevocabulary = parseInt(val.form_percentage * 100) / 100;
          }
          if(y > 80){
            this.scorefluency = "Advance";
          } 
          else if( y > 70){
            this.scorefluency= "Good";
          } 
          else if(y > 60){
            this.scorefluency= "Avarage";
          }
          else if(y > 10){
            this.scorefluency= "Poor";
          }
          else if(y < 10){
            this.scorefluency= parseInt(val.fluency_percentage * 100) / 100;
          }
          if(z > 80){
            this.scorecontent = "Advance";
          } 
          else if( z > 70 ){         
            this.scorecontent = "Good";
          } 
          else if( z > 60 ){
            this.scorecontent = "Avarage";
          }
          else if( z > 10){
            this.scorecontent = "Poor";
          }
           else if( z < 10){
            this.scorecontent = parseInt(val.content_percentage * 100) / 100 ;
          }
          if(  v > 80){
            this.scorepronunciation = "Advance";
          } 
          else if(v > 70){
            this.scorepronunciation = "Good";
          } 
          else if(v > 60){
            this.scorepronunciation = "Avarage";
          }
          else if( v > 10){
            this.scorepronunciation = "Poor";
          }
          else if( v < 10){
            this.scorepronunciation = parseInt(val.pronunciation_percentage * 100) / 100;
          }
        },
      }  
    },
    methods: { 
      fluency_toggle(default_limit, filters_length) {
        this.limit_by_flu = (this.limit_by_flu === default_limit) ? filters_length : default_limit;
      },
      vocabulary_toggle(default_limit, filters_length) {
        this.limit_by_voca = (this.limit_by_voca === default_limit) ? filters_length : default_limit;
      },
      pro_toggle(default_limit, filters_length) {
        this.limit_by_pro = (this.limit_by_pro === default_limit) ? filters_length : default_limit;
      },
      cont_toggle(default_limit, filters_length) {
        this.limit_by_voca = (this.limit_by_voca === default_limit) ? filters_length : default_limit;
      },
      scrollRightAnswer(){
          var element = document.querySelector("#scrollError");
          // scroll to element
          element.scrollIntoView({ behavior: 'smooth', block: 'end'}); 
      },
      ErrorDetect(){
         //grammar error
          const score_grammar = parseInt(this.answer_sheet.grammar_percentage * 100) / 100
          if(score_grammar < 90){
            this.error_grammar_icon = false;
          } else{
            this.error_grammar_icon = true;
          }
          //spelling error
          const score_spelling = parseInt(this.answer_sheet.spelling_percentage * 100) / 100
          if( score_spelling < 90){
            this.error_spelling_icon = false;
          } else {
            this.error_spelling_icon = true;
          }
      }
    },
    mounted(){
      //this.bottomHide = localStorage.getItem('answer_bottom_hide');
      setTimeout(() =>  this.ErrorDetect(), 200);
     
    }

}
</script>
<style lang="scss" scoped>
.md-layout-item {
    height: auto;
}
ul li{
  list-style-type: disc;
}
.ap-main .md-layout {
  align-items: center;
  margin-top: 2px;
}
.display-content{
  display: contents;
}
.b-d-t:first-child{
  border-top: 1px solid #c0c0c0;
}
.info_button svg{
  height: 20px;
  width: 20px;
  margin-top: 5px;
}
</style>
