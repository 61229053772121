<template>
<div class="audio">
  <div class="md-sl__playerBody">
    <div class="md-sl__wave_body mt-6">
      <div class="md-sl__wave_text">
       <p v-if="this.isActive" style="color:#000"><span class="">Current Status <span class="font-bold">Playing</span> </span></p>
       <p v-if="this.CheckPlay" style="color:#000"><span class="text-black">Current Status <span class="font-bold">Stop</span></span></p>
      </div>
      <div class="md-sl__wave_text md-volume_control_button">
          <span class="md-muteHandle_icon" @click="Mutehandle()" v-bind:class="{ mute: isSoundActive}">
            <MuteIcon/>
          </span>
          <input @click="unMutehandle()" data-action="volume" id="VolumeControl" type="range" min="0" max="1" value=".5" step="0.1" style="width: 100%" >
      </div>
      <vue-wave-surfer  ref-link="player" :src="src" :options="options" ref="surf" id="waveform" class="md-sl__wave"></vue-wave-surfer>
      <div  @click="play()" v-bind:class="{ active: isActive}" class="play_button__mode">
         <span><SoundIcon/></span>
      </div>
    </div>
   </div>
</div>
</template>
<script>
import SoundIcon from '../../../../assets/svg/sound.svg';
import MuteIcon from '../../../../assets/svg/muteIcon.svg'
export default {
  name: 'audio',
   props: {
        src: {
          default: true
        },
   },
  components:{
     SoundIcon,
     MuteIcon
  },
  data () {
    return {
      options: {
          barGap: 3,
          barWidth: 1.3,
          barHeight:.7,
          mediaType:"audio",
          normalize: false,
          partialRender: false,
          progressColor: "#8c58c2",
          waveColor:"#ced2e0",
          interact:false,
          cursorWidth: 2,
          responsive: true,
      },
      isActive: '',
      isSoundActive: '',
      CheckPlay:true,
      CurrentTime: '',
      stop: ''
    }
  },
  watch:{
    src: {
      handler(val){
        this.file_path = val
        if(val != null){
          this.audioprocess.load(val);
        }
      },
    }  
  },
  mounted () {
    //new class set
    for (let index = 1; index < 8; index++) {
      if(index % 2 == 1){
      let x = document.getElementsByTagName('wave')[index]
       x.classList.add('wave_border');
      }
    }
    // Audio ready
    this.audioprocess.on('ready', () => {
     // this.play()
      if(this.audioprocess.isPlaying()){
          this.isActive = true
      }
      if(!this.audioprocess.isPlaying()){
          this.isActive = false;
      }
     
   });
    // Audio Timer count
    this.audioprocess.on("audioprocess", () => {
      // volume 
      const volumeslider = document.querySelector('#VolumeControl');
        volumeslider.addEventListener("mouseup",() => {
          changevolume(volumeslider.value)
        });
        const  changevolume = (volume) =>{
        this.audioprocess.setVolume(volume)
        console.log(volume) 
       }  
       
    })
    
  },
  
  methods: {
          play(){
            this.audioprocess.playPause();
            if(this.audioprocess.isPlaying()){
              this.isActive = true;
              this.CheckPlay = false;
            }
            if(!this.audioprocess.isPlaying()){
                this.isActive = false;
                this.CheckPlay = true;
                clearInterval(this.CurrentTime);
            }
          },
          Mutehandle(){
           this.audioprocess.setMute(true)
            this.isSoundActive = true;                   
          },
          unMutehandle(){
            this.audioprocess.setMute(false)
            this.isSoundActive = false;                  
          },
  },
  computed: {
    audioprocess() {
      return this.$refs.surf.waveSurfer;
    }
  },
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
.md-sl__wave{
    border: 1px solid #bebebe;
    border-radius: 4px;
    background: #fff;
}

.md-sl__wave_body{
    height: 136px;
    margin-left: 0px;
    border-radius: 4px;
}
.play_button__mode.active .puase{
 fill: none;
}
.play_button__mode .play{
  fill: none;
}
.play_button__mode.active .play{
  fill: #fff;
}
.play_button__mode  {
  position: absolute;
  top: 36%;
  zoom: .8;
  z-index: 2;
}
.play_button__mode  span{
    width: 40px;
    height: 40px;
    line-height: 40px;
    background: #ffffffb8;
    box-shadow: 0 2px 5px 1px rgb(158 158 158 / 50%);
    position: absolute;
    border-radius: 50px;
    display: flex;
    align-items: center;
    margin-left: 10px;
    cursor: pointer;
}
.play_button__mode.active  span{
    background: #7432b8;
}
.play_button__mode svg{
    position: absolute;
    margin-left: 5px;
    width: 45px;
    height: 45px;
}
.md-volume_control_button{
    right: 20px;
    width: 90px;
    display: flex;
    border-radius: 3px;
    height: 30px;
    justify-content: flex-end;
    align-items: center;
}
.md-volume_control_button input[type='range']{
  zoom: .65;
  width: 75px !important;
  background-image:-webkit-linear-gradient(left, #7432b8, #7432b8);
  cursor: pointer;
}
/* input[type='range']::after{
  content: '';
  width: 11px;
  height: 11px;
  background: #7432b8;
  position: absolute;
  right: 6px;
  margin-top: -4px;
  border-radius: 50px;
} */
input[type='range'] {
  width: 40px !important;
  -webkit-appearance: none;
   background: #434343;
}

input[type='range']::-webkit-slider-runnable-track {
  height: 5px;
  -webkit-appearance: none;
  margin-top: -1px;
  background-color: #7432b8;
}

input[type='range']::-webkit-slider-thumb {
  width: 5px;
  -webkit-appearance: none;
  height: 5px;
  cursor: ew-resize;
  background: #434343;
  box-shadow: -4px 0 0 4px #7432b8;
  z-index: 111;
  border-radius: 50px;
}
input[type="range"]::-moz-range-track {  
  background-color: #434343;
}
input[type="range"]::-ms-fill-upper {  
  background-color: #434343;
}
.md-muteHandle_icon{
  margin-right: -6px;
  z-index: 11;
}
.md-muteHandle_icon .circle_lg{
  stroke-dasharray: 600px 1500px;
  transition: all .5s;
}

.md-muteHandle_icon .circle_sm{
  opacity: 1;
  transition: all .25s;
}

.mute svg .circle_lg{
  stroke-dashoffset: -1360px;
}
.md-sl__wave_text {
  margin-top: 5px;
}
.mute svg .circle_sm{
  opacity: 0;
}
.md-sl__wave{
    border: 1px solid #bebebe;
    border-radius: 4px;
    background: inherit !important;
    margin-top: 5px;
    padding-top: 15px;
    zoom: .78;
}
.play_button__mode{
    top: 36%;
    zoom: .7;
}
.play_button__mode span{
    background: #762cb5;
}
.play_button__mode svg{
   fill: #fff;
}
</style>