<template>
    <div>
        <PrograssEvaluate :answer_sheet="answer_sheet"/>
        <Question :answer_sheet="answer_sheet"/>
        <Answer :answer_sheet="answer_sheet"/>
        <SimpleAnswer :answer_sheet="answer_sheet" id="scrollError"/>
    </div>
</template>


<script>
import { 
  SimpleAnswer
  } from "../index";
import PrograssEvaluate from "./prograssEvaluate.vue";
import Question from "./Question.vue";
import Answer from "./answer.vue";
export default {
    components: {
        Question,
        Answer,
        PrograssEvaluate,
        SimpleAnswer
    },
    props:{
        answer_sheet: {
            default: true 
        }
    },
};
</script>