<template>
    <div class="evaluation_header">
        <Alert background-color="#6616d0" classObject="rounded-lg p-2">
            <div class="flex flex-wrap justify-between justify-sm-center justify-xs-center">
                <div class="mx-2 p-2">
                    <div class="text-white text-sm-center text-xs-center">
                        <div class="flex align-center">
                            <h3 class="text-uppercase text-white">{{question_type.section}}</h3>
                            <span class="pl-2 pr-2 m-2" style="background:#a40fbc;border-radius: 4px;">{{question_type.title}}</span>
                        </div>
                        <div class="flex align-center" v-if="question_type.section == 'SPEAKING'">
                            <span class="font-bold text-xl md-eval__back" v-if="question_type.slug =='answer-short-question' ">{{parseInt(answer_sheet.content_percentage * 100) / 100}}</span>
                            <span class="font-bold text-xl md-eval__back" v-if="question_type.slug !='answer-short-question' ">{{parseInt(answer_sheet.content_percentage * 100) / 100}}</span>
                            <small class="pl-2 text-gray1">OVERALL SCORE</small>
                        </div>
                        <div class="flex align-center" v-if="question_type.section == 'READING'">
                            <span class="font-bold text-xl md-eval__back">{{parseInt(answer_sheet.reading_percentage * 100) / 100}}</span>
                            <small class="pl-2 text-gray1">OVERALL SCORE</small>
                        </div>
                        <div class="flex align-center" v-if="question_type.section == 'WRITING'">
                            <span class="font-bold text-xl md-eval__back">{{parseInt(answer_sheet.writing_percentage * 100) / 100}}</span>
                            <small class="pl-2 text-gray1">OVERALL SCORE</small>
                        </div>
                        <div class="flex align-center" v-if="question_type.section == 'LISTENING'">
                            <span class="font-bold text-xl md-eval__back">{{parseInt(answer_sheet.listening_percentage * 100) / 100}}</span>
                            <small class="pl-2 text-gray1">OVERALL SCORE</small>
                        </div>
                    </div>
                </div>
                <div class="mx-2 flex align-center">
                    <div class="md-grid_right">
                        <span class="font-bold text-white">{{student_info.name}}</span>
                        <span class="text-gray1">{{answer_sheet.created_at}}</span>
                    </div>
                    <div class="p-3 rounded text-white">
                        <md-avatar class="md-avatar-icon md-large__img">
                            <img src="/images/result-sheet.png" alt="" class="">
                        </md-avatar>
                    </div>
                </div>
            </div>
        </Alert>
    </div>
</template>

<script>
import { Alert} from '@/components';
export default {
    components : {
        Alert
    },
    props:{
       answer_sheet: {
           default: true
       }
    },
    data() {
        return {
          question: [],
          question_type: [],
          student_info: ''  
        }
    },
    watch:{
      answer_sheet: {
        handler(val){
        // answer_sheet
        this.question = val.question
        this.question_type = this.question.question_type
        this.student_info = val.student_info
        },
      }  
    },
    methods: { 
       
    }
}
</script>
