<template>
  <md-dialog :md-active="active" class="alert_body">
        <span @click="$emit('update:active', !active)" class="pointer mt-3 absolute alert__close_button">
          <md-icon>close</md-icon>
        </span>
    <div class="p-4">
      <h2 class="mt-6 text-center text-uppercase text-base mb-8 line-normal" v-html="message"></h2>
      <div class="flex justify-center">
        <md-button class="md-dense m-0 bg-success rounded  text-white" @click="confirme()">View Study Guide</md-button>
      </div>
    </div>
  </md-dialog>
</template>

<script>
export default {
  props: {
    active: Boolean,
    message: {
        type: String,
        default: () => 'Are you sure you want to delete this item ?'
    }
  },
  methods: {
    confirme() {
      this.$emit("update:active", false);
      this.$emit("confirm");
      this.$emit("success" ,false);
    },
  },
};
</script>
