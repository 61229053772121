<template>
    <div>
        <div class="md-question__radio md-sl__font_600">
            <md-content id="handleItem" class="p-3 br-3 md-sl__font_600 md-sl__x1 mb-4 m-0">
                <div v-html="this.highlightWords" class="answers" id="fill_in_gap">
                </div>
                <div class="answer_body">
                    <div id="dropDown">  
                        <select v-for="(item, index) in answer" :key="index" v-bind:class="[item.is_wrong === 1 ? 'wrong_answer' : '']">
                            <option disabled selected >
                                {{item.title}}
                            </option>
                        </select>
                    </div>
                </div>
            </md-content>
        </div>
    </div>
</template>

<script>
// import DrapFiled from "./drapFiled.vue";
export default {
    name: 'DropDown',
    data() {
      return {
          id: '',
          event: '',
          array:[],
          answer: [],
          highlightWords: [],
          form: {
            answer_options: []
          }
      }
    },
    props: {
        case_item: {
           default: () => true
        },
        answer_down: {
           default: () => true
        },
        answer_drop:{
            default: () => true
        },
        active_green: {
            default: () => true
        }
    },
    mounted(){
        this.highlightWord();
        setTimeout(() => this.data(),500);
        this.Sort()
    },
    methods:{
        Sort(){
            this.answer = this.answer_drop.sort((a, b) => a.serial_no - b.serial_no)
        },
        highlightWord(){
            let text = this.case_item.title.replace(/(<p[^>]+>|<p>|<\/p>)/g, "");
            console.log('hello',text)
            this.highlightWords = text.replace(/<(\/)?i([^>]*)>/g, '<$1span id="dropdown"$2><$1span id="remove"$2>');
        },
        data(){
            const tx = document.querySelectorAll('#dropdown')
            for (let i = 0; i < tx.length; i++) {
                tx[i].id = "fill_in_gap"+`${i+1}`;  
            } 
            const val = document.querySelectorAll('#dropDown select')
            for (let j = 0; j < val.length; j++) {
                val[j].id =("fill_in_gaps"+`${j+1}`); 
                val[j].classList.add('fill_in_gap')
            } 
            const x = document.querySelectorAll('#fill_in_gap span')
            for (let k = 0; k < x.length; k++) {
                const node = document.getElementById("fill_in_gaps"+`${k+1}`)
                document.getElementById("fill_in_gap"+`${k+1}`).appendChild(node); 
                document.getElementById("remove").remove()
            } 
        },
    }
}
</script>

<style scoped>
.answers .target{
    border: 1.5px solid #cfcaca;
    min-width: 110px;
    margin: 0 3px;
    text-align: center;
    min-height: 30px;
}
select option:disabled {
    color: #000;
    font-weight: bold;
}
.answers select{
    border: 0px solid #ced4da;
    color: #15be15;
    pointer-events: none;
    cursor: default;
    -webkit-appearance: none;
    -moz-appearance: none;
    text-indent: 1px;
    text-overflow: '';
}

.answer_body{
    display: none;
}
.back-light_gray{
   background: #c6d0d9
}
.back-gray-light{
   background: #e6e4e5
}
.target::before{
    content: '1';
    position: absolute;
}
.target span{
    background: whitesmoke;
    z-index: 1;
}
.answers .wrong_answer{
    color: red;
}
</style>