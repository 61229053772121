<template>
    <div>
       <span v-for="(item, index) in  this.highlightWords" :key="index" class="answers" v-bind:class="[item.matched === false ? 'wrong_answers' : 'right_answers']">
            {{item.name}}
        </span>
    </div>
</template>

<script>
export default {
    name: 'DropDown',
    data() {
      return {
          highlightWords: [],
      }
    },
    props: {
        answer: {
           type: String,
        },
        question:{
            type: String, 
        }
    },
    mounted(){
        this.highlightWord();
    },
    methods:{
        highlightWord(){
            const text1 = this.question.replace(/<[^>]+>/g, '').replace(/&nbsp;/g, ' ')
            const Array1 = text1.split(" ");
            const text2 = this.answer.toString()
            console.log('f',text2)
            const Array2 = text2.split(" ");
            console.log('r',Array2)
             console.log(text1)
            let map = {};
            Array2.forEach(i => map[i] = false);
            Array1.forEach(i => map[i] === false && (map[i] = true));
            this.highlightWords = Object.keys(map).map(k => ({ name: k, matched: map[k] }));
           
            console.log('hh',this.highlightWords)
        },
    }
}
</script>
<style>
.wrong_answers{
    color: red;
    font-weight: 600;
}
.right_answers{
    font-weight: normal;
}
</style>