<template>
<div>
   <Alert classObject="rounded-lg p-2 pt-8 flex justify-right ev-button">
        <md-button class="rounded br-6 m-1 b-d p-1 text-blue" style="height:auto" :class="answer_sheet.attempt == 1 ? 'back-blue' : ''">
            Attempt 1
        </md-button>
        <md-button class="rounded br-6 m-1 b-d p-1 text-blue" style="height:auto" :class="answer_sheet.attempt == 2 ? 'back-blue' : ''">
            Attempt 2
        </md-button>
        <md-button class="rounded br-6 m-1 b-d p-1 text-blue" style="height:auto" :class="answer_sheet.attempt == 3 ? 'back-blue' : ''">
            Attempt 3
        </md-button>
        <md-button @click="Prev()" class="back-green rounded br-6 m-1 b-d p-1 text-white" style="height:auto">
            Back to Question
        </md-button>
        <md-button @click="NextQuestion()" class="back-orange br-6 rounded m-1 b-d p-1 text-white md-dense" style="height:auto">
            Go to Next Question
        </md-button>
        
    </Alert> 
</div>
</template>

<script>
import { Alert} from '@/components';
import { mapActions} from 'vuex';
  export default {
     components : {
        Alert
    },
    props:{
       answer_sheet: {
           default: true 
       }
    },
    data() {
      return {
        NextQuestionDetect: false,
        currentQuestion: false,
      }
    },
    methods:{
      ...mapActions({
        actTestDetails: "testCreation/actTestDetails",
      }),
      NextQuestion(){
        this.$router.go(-1);
        this.$root.NextQuestionDetect = true
      },
      Prev(){
        this.$router.go(-1)
        this.$root.currentQuestion = true 
      }
    }
}
</script>
<style scoped>
 .back-blue{
   color: #fff;
 }
</style>