<template>
    <div class="evaluation_header">
        <div v-if="answer_sheet.question.sample_answers.length > 0">
            <Alert background-color="#f0faef" classObject="rounded-lg p-2" v-if="answer_sheet.question.question_type.section == 'SPEAKING'">
                <div class="flex justify-between align-center">
                    <div class="">
                        <span class="font-bold br-6 pl-1 pr-1 ml-2 pb-4">Sample Answer</span>
                        <span>
                            <button type="button" :class="this.isActive? 'active': ''" v-on:click="genderHandle('male')"  class="pl-3 pr-3 initial_color">Male</button>
                            <button type="button" :class="this.isActive? '': 'active'" v-on:click="genderHandle('female')"  class="pl-3 pr-3 initial_color">Female</button>
                        </span>
                    </div>
                </div>
                <div v-for="(resources,index) in answer_sheet.question.sample_answers" :key="index">
                    <span v-if="isActive">
                        <Audio :src="resources.resources[0].file_path"/>
                    </span>
                    <span v-if="!isActive">
                        <Audio :src="resources.resources[1].file_path"/>
                    </span>
                </div>
            </Alert>
            <Alert classObject="rounded-lg pt-2 pb-2" v-if="answer_sheet.question.question_type.section != 'SPEAKING'">
                <div class="flex justify-between align-center" v-if="answer_sheet.question.sample_answers != null">
                    <div class="md-layout-item md-size-100">
                        <h3 class="font-bold br-6 pr-1 pb-1" >Sample Answer</h3>
                        <div v-for="(item,index) in answer_sheet.question.sample_answers" :key="index" class="rounded-lg p-2 p-3 mt-4 br-3 md-sl__font_600 md-sl__x1 mb-4 m-0 b-d b-d-c" style="margin-top:10px">
                            <h3 style="font-weight:500" v-html="item.title"></h3>

                        </div>
                    </div>
                </div>
            </Alert>
        </div>
        <Alert>
            <StudyGuide :question="answer_sheet.question"/> 
        </Alert>
        <Alert classObject="rounded-lg" v-if="answer_sheet.question.explanations.length > 0">
            <Explanation :answer_sheet="answer_sheet"/> 
            
        </Alert>
    </div>
</template>

<script>
import { Alert} from '@/components';
import Audio from "./speaking/audio.vue";
import Explanation from "./explanation.vue";
import StudyGuide from "./StudyGuide.vue";
export default {
    components : {
        Alert,
        Audio,
        Explanation,
        StudyGuide
    },
    props:{
       answer_sheet: {
           default: true 
       }
    },
    data() {
        return {
          male: 'https://kazi-blubird.sfo2.digitaloceanspaces.com/apical/speaking/audio/Andrew_Keen__voice__en.wav',
          female: 'https://kazi-blubird.sfo2.digitaloceanspaces.com/apical/speaking/audio/female_voice.wav',
          question: '',
          question_type: [],
          isActive: true,
          activeId: 'Male',
        }
    },
    watch:{
      answer_sheet: { 
        handler(val){
        this.question = val.question;
        this.question_type = this.question.question_type;
        },
      }  
    },
    methods:{ 
        genderHandle(element) {
           console.log(element)
            if (element == 'male') {
                this.isActive = true;
            } else {
                this.isActive = false;
            }
        },
    },
}
</script>
