<template>
    <div>
       <span v-for="(item, index) in  this.highlightWords" :key="index" class="answers" v-bind:class="[item.matched === 'spelling' ? 'spelling_wrong' : 'right_answers',item.matched === 'grammar' ? 'grammar_wrong' : 'right_answers']">
           <span> {{item.name}}</span>
        </span>
    </div>
</template>

<script>
export default {
    name: 'writing',
    data() {
      return {
          highlightWords: [],
          spelling: [],
          grammar: [],
          content:[]
      }
    },
    props: {
        answer: {
           type: Object,
        },
    },
    mounted(){
        setTimeout(() =>  this.highlightWord(), 600);
    },
    methods:{
        highlightWord(){
            //question content 
            const content_text = this.answer.answer[0].replace(/<[^>]+>/g, '').replace(/&nbsp;/g, ' ').replace(/,/g, '').replace(/\./g,'')
            this.content = content_text.split(" ");
            //student answer array
            const answer = this.answer.evaluation_details.response.grammar.errors
            //filter spelling error
            const data = answer.filter(spelling => spelling.type == 'spelling')
                const spelling = data.map(function(item) {
                    return item['bad'];
                });       
                const string = spelling.toString()
                const spelling_arr = string.replace(/,/g, ' ');
                this.spelling = spelling_arr.split(" ");
                console.log(this.spelling)
            //filter grammar error
            const data1 = answer.filter(spelling => spelling.type == 'grammar')
                    const grammar = data1.map(function(item) {
                       return item['bad'];
                    });       
                   const string1 = grammar.toString()
                   const grammar_arr = string1.replace(/,/g, ' ');
                   this.grammar = grammar_arr.split(" ");
                   console.log(this.grammar)
            //matching and genarate spelling and grammar object 
            let map = {};
            this.content.forEach(i => map[i] = 'right');
            this.spelling.forEach(i => map[i] === 'right' && (map[i] = 'spelling'));
            this.grammar.forEach(i => map[i] === 'right' && (map[i] = 'grammar'));
            this.highlightWords = Object.keys(map).map(k => ({ name: k, matched: map[k] }));
            console.log('hh',this.highlightWords)
        }
            
    }
}
</script>
<style>
.spelling_wrong{
    color: red;
    font-weight: 600;
}
.grammar_wrong{
    color: #000000de;
    text-decoration: none;
    position: relative;
    margin: 0px 2px;
}
.grammar_wrong span{
    color: #000000de;
}
.grammar_wrong::before{
    content: '';
    width: 100%;
    position: absolute;
    left: 0;
    bottom: 0px;
    border-bottom: 2px solid blue;
}
.right_answers{
    font-weight: normal;
}
</style>