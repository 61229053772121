<template>
    <div>
        <div class="md-question__radio md-sl__font_600">
            <md-content id="handleItem" class="pt-3 br-3 md-sl__font_600 md-sl__x1 mb-4 m-0"  style="background-color: #fff0;">
                <div v-html="this.text_content" class="answers" id="dropDown">
                </div>
                <div class="answer_body">
                    <div id="dropDowns" v-for="(item, index) in answer" :key="index">  
                        <span class="select">
                            <!-- <option :value="items.serial_no" v-for="(items, index) in item.drop_down_options" :key="index" v-bind:class="[item.dd_serial_no === 1 ? 'right_answer' : 'hidden']">
                                {{items.dd_value}}
                            </option> -->
                            <span v-for="(items, index) in item.drop_down_options" :key="index">
                                <span class="right_answer" v-if="items.dd_serial_no == 1">{{items.dd_value}}</span>
                            </span>
                        </span>
                        
                    </div>
                </div>
            </md-content>
        </div>
    </div>
</template>

<script>
// import DrapFiled from "./drapFiled.vue";
export default {
    name: 'DropDown',
    data() {
      return {
          array:[],
          answer: [],
          text_content: [],
      }
    },
    props: {
        case_item: {
           default: () => true
        },
        answer_down: {
           default: () => true
        },
    },
    mounted(){
        this.highlightWord();
        setTimeout(() => this.data(),500);
        this.Sort()
    },
    methods:{
        Sort(){
            this.answer = this.answer_down.question_options.sort((a, b) => a.serial_no - b.serial_no)
            
        },
        highlightWord(){
            let text = this.case_item.title.replace(/(<p[^>]+>|<p>|<\/p>)/g, "");
            console.log(text)
             this.text_content = text.replace(/ (\/)?\[[^\]]*]/g, '<$1span id="dropdowns"$2><$1span id="remove"$2></$1span></$1span>');
        },
        data(){
            const tx = document.querySelectorAll('#dropdowns')
            for (let i = 0; i < tx.length; i++) {
                tx[i].id = "drop_down"+`${i+1}`;  
            } 
            const val = document.querySelectorAll('#dropDowns > .select')
            for (let j = 0; j < val.length; j++) {
                val[j].id =("drop_downs"+`${j+1}`); 
                val[j].classList.add('fill_in_gap')
            } 
            const x = document.querySelectorAll('#dropDown span')
            for (let k = 0; k < x.length; k++) {
                const node = document.getElementById("drop_downs"+`${k+1}`)
                document.getElementById("drop_down"+`${k+1}`).appendChild(node); 
                document.getElementById("remove").remove()
            } 
        },
    }
}
</script>

<style scoped>
.answers .target{
    border: 1.5px solid #cfcaca;
    min-width: 110px;
    margin: 0 3px;
    text-align: center;
    min-height: 30px;
}
select option:disabled {
    color: #000;
    font-weight: bold;
}
.answers select{
    border: 0px solid #ced4da;
    color: #15be15;
    pointer-events: none;
    cursor: default;
    -webkit-appearance: none;
    -moz-appearance: none;
    text-indent: 1px;
    text-overflow: '';
}

.answer_body{
    display: none;
}
.back-light_gray{
   background: #c6d0d9
}
.back-gray-light{
   background: #e6e4e5
}
.target::before{
    content: '1';
    position: absolute;
}
.target span{
    background: whitesmoke;
    z-index: 1;
}
.answers .wrong_answer{
    color: red;
}
.right_answer{
    color: #000 !important;
    font-weight: 700;
    padding: 3px;
    font-size: 1rem;
}
</style>