<template>
    <div>
        <div class="md-question__radio md-sl__font_600">
            <md-content style="background-color: #fff0" id="handleItem" class="p-3 br-3 md-sl__font_600 md-sl__x1 mb-4 m-0">
                <div v-html="this.highlightWords" class="answers" id="fill_in_gap">
                </div>
                <div class="answer_body">
                    <div id="dropDown">  
                        <em v-for="(item, index) in answer" :key="index" v-bind:class="[item.is_wrong === 1 ? 'wrong_answers' : '']">
                            <span disabled selected >
                                {{item.title}}
                            </span>
                        </em>
                    </div>
                </div>
            </md-content>
        </div>
    </div>
</template>

<script>
// import DrapFiled from "./drapFiled.vue";
export default {
    name: 'DropDown',
    data() {
      return {
          id: '',
          event: '',
          array:[],
          answer: [],
          highlightWords: [],
          form: {
            answer_options: []
          }
      }
    },
    props: {
        case_item: {
           default: () => true
        },
        answer_down: {
           default: () => true
        },
        answer_drop:{
            default: () => true
        },
        active_green: {
            default: () => true
        }
    },
    mounted(){
        this.highlightWord();
        setTimeout(() => this.data(),500);
        this.Sort()
    },
    methods:{
        Sort(){
            this.answer = this.answer_drop.sort((a, b) => a.serial_no - b.serial_no)
        },
        highlightWord(){
            let text = this.case_item.title.replace(/(<p[^>]+>|<p>|<\/p>)/g, "");
            console.log('hello',text)
            let reg = /\[([^\]]+)]/g
            let x = text.replace(reg, '<i>');
            this.highlightWords = x.replace(/<(\/)?i([^>]*)>/g, '<span id="dropdown"><span id="remove"></span></span>');
           console.log(this.highlightWords)
        },
        data(){
            const tx = document.querySelectorAll('#dropdown')
            for (let i = 0; i < tx.length; i++) {
                tx[i].id = "fill_in_gap"+`${i+1}`;  
            } 
            const val = document.querySelectorAll('#dropDown em')
            for (let j = 0; j < val.length; j++) {
                val[j].id =("fill_in_gaps"+`${j+1}`); 
                val[j].classList.add('fill_in_gap')
            } 
            const x = document.querySelectorAll('#fill_in_gap span')
            for (let k = 0; k < x.length; k++) {
                const node = document.getElementById("fill_in_gaps"+`${k+1}`)
                document.getElementById("fill_in_gap"+`${k+1}`).appendChild(node); 
                document.getElementById("remove").remove()
            } 
        },
    }
}
</script>
<style scoped>
#handleItem{
    padding: 0.75rem;
}
.question_fig #handleItem{
    padding: 0rem !important;
}
</style>