<template>
    <div class="evaluation_prograss">
        <Alert classObject="rounded-lg">
            <div class="question_list">
                <div class="evaluation_header">
                    <Alert>
                        <md-content v-if="question.question_type.short_title == 'WFD'">
                            <WriteFromDictation :question="question.transcript" :answer="answer_sheet.answer"/>
                        </md-content>
                        <div class="question_list" v-if="question.question_type.short_title != 'WFD'">
                            <div class="text-base line-height-27">
                                <span class="student_answer_checked">
                                    <span>
                                        <li v-for="(item, index) in this.answers_short" :key="index" class="my-0 flex align-center" v-bind:class="{ active: disable ? item.is_correct_answer :  '' }">
                                            <md-checkbox 
                                                v-if="question.question_type.short_title == 'MCQMA'"
                                                disabled :disable="disable"  :value="item.is_correct_answer" v-model="disable" :checked="item.is_correct_answer"> 
                                            </md-checkbox>
                                            <md-radio 
                                                v-if="question.question_type.short_title != 'MCQMA'"
                                                disabled :disable="disable"  :value="item.is_correct_answer" v-model="disable" :checked="item.is_correct_answer"> 
                                            </md-radio>
                                            <Content :contentWidth ="width" :text="item.title" class="w-100" v-bind:class="[answer[index].is_wrong === 1 ? 'active_red' : 'active_green']"/>
                                        </li>
                                    </span>
                                </span>
                            </div>  
                        </div>
                    </Alert>
                </div>
            </div>
        </Alert>
    </div>
</template>
<script>
import { Alert} from '@/components';
import Content from "@/components/atom/Content";
import WriteFromDictation from './question/writeFromDictation.vue'
export default {
    components: {
      Alert,
      Content,
      WriteFromDictation
    },
    props:{
        answer_sheet: {
           default: true 
        },
        activeValue: {
           default: true 
        },
        question_types:{
            default: true
        },
        question:{
            default: true
        },
        answer:{
            default: true
        },
    },
    data() {
        return {
           disable: 1,
            answers:[],
            answers_short:[],
            questions:[],
            width:'w-100'
        }
    },  
    mounted(){
        setTimeout(() => {
          this.Sort()
        },600); 
    },
    methods:{
        Sort(){
            if(this.answer !== null){
              this.answers_short =  this.answer.sort((a, b) => a.new_serial - b.new_serial)
            }
        }
    }
}
</script>
