<template>
    <div class="evaluation_prograss pt-4">
        <Alert classObject="rounded-lg">
            <div class="flex justify-between align-center">
                <div class="flex align-center">
                    <h2 style="margin-top: -3px;">Your Answer</h2>
                </div>
            </div> 
            <div class="question_list mt-6 mb-4">
                <div class="evaluation_header mt-6">
                    <Alert classObject="rounded-lg p-2 p-3 mt-4 br-3 md-sl__font_600 md-sl__x1 mb-4 m-0 b-d b-d-c">
                        <md-content >
                            <Essay :answer="answer_sheet.answer_sheet_details"/>
                        </md-content>
                    </Alert>
                </div>
            </div>
        </Alert>
    </div>
</template>
<script>
import { Alert} from '@/components';
import Essay from './essay.vue'
export default {
    components: {
      Alert,
      Essay
    },
    props:{
        answer_sheet: {
           default: true 
        }
    },
    data() {
        return {
           
        }
    },  
}
</script>
