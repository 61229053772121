<template>
  <div>
    <EvaluationHeader :answer_sheet="answer_sheet"/>
    <Speaking :answer_sheet="answer_sheet" v-if="question_type.section == 'SPEAKING'"/>
    <Reading :answer_sheet="answer_sheet" v-if="question_type.section == 'READING'"/>
    <span v-if="question_type.short_title != 'SST'">
        <Listening :answer_sheet="answer_sheet" v-if="question_type.section == 'LISTENING'"/>
      <Score :answer_sheet="answer_sheet" v-if="question_type.section != 'WRITING'"/>
    </span>
    <Writing :answer_sheet="answer_sheet" v-if="question_type.section == 'WRITING' || question_type.short_title == 'SST'"/>
    <AllScore :answer_sheet="answer_sheet" v-if="question_type.section == 'WRITING' || question_type.short_title == 'SST'"/>
  </div>
</template>

<script>
import { mapActions,mapGetters} from 'vuex';
import AllScore from "./writing/score.vue";
import { 
  EvaluationHeader,
  Score,
  Speaking,
  Reading,
  Writing,
  Listening
  } from "./index";
export default {
  components: {
    EvaluationHeader,
    Score,
    Speaking,
    Reading,
    Writing,
    Listening,
    AllScore
  },
  data() {
    return {
      answer_sheet_id: '',
      answer_sheet:'',
      question: '',
      question_type:''
    }
  },
  computed: { 
    ...mapGetters({
      getResults: "result/getResults",
    }),
  },
  mounted(){
  
  },
  methods:{
    ...mapActions({
      actShowResult: "result/actShowResult",
      actResultList: "result/actResultList"
    }),
  },
  created() {
    this.answer_sheet_id = localStorage.getItem('answer_sheet_id');
    this.actShowResult(this.answer_sheet_id)
        .then(res =>{ 
          this.question_type = res.question.question_type;
          this.answer_sheet = res;
          localStorage.setItem('question_id',res.question.id);
        });
  },
 
};
</script>
