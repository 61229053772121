<template>
  <div class="chart" >
    <span class="font-bold md_high">High</span>
    
    <div class="" id="chart_body"><span class="box"></span></div>
    <LineChartGenerator
    :chart-options="chartOptions"
    :chart-data="chartData"
    :chart-id="chartId"
    :dataset-id-key="datasetIdKey"
    :plugins="plugins"
    :css-classes="cssClasses"
    :styles="styles"
    :width="300"
    :height="226"
  />
    <span class="font-bold md_low">Low</span>
    <!-- {{options}} -->
  </div>
</template>

<script>
import { Line as LineChartGenerator } from "vue-chartjs/legacy";

import {
  Chart as ChartJS,
  Title,
  Tooltip,
  Legend,
  LineElement,
  LinearScale,
  CategoryScale,
  PointElement,
} from "chart.js";

ChartJS.register(
  Title,
  Tooltip,
  Legend,
  LineElement,
  LinearScale,
  CategoryScale,
  PointElement
);
export default {
  name: "Home",
  components: {
     LineChartGenerator,
  },
  props:{
      chartData: {
        type: Object,
        required: true
      },
      chartOptions: {
        type: Object,
        required: true
      },
       chartId: {
      type: String,
      default: "line-chart",
    },
    datasetIdKey: {
      type: String,
      default: "label",
    },
    width: {
      type: Number,
      default: 400,
    },
    height: {
      type: Number,
      default: 400,
    },
    cssClasses: {
      default: "chart_canvas",
      type: String,
    },
    styles: {
      type: Object,
      default: () => {},
    },
    plugins: {
      type: Array,
      default: () => [],
    },
  },
  data() {
    return {
      datacollection: null,
      words:[],
      Offset: [],
      Word: '',
      label: '',
      Off: [],
      
    }
  },   
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style lang="scss" scoped>
h3 {
  margin: 40px 0 0;
}
ul {
  list-style-type: none;
  padding: 0;
}
li {
  display: inline-block;
  margin: 0 10px;
}
a {
  color: #42b983;
}
.md_high{
  margin-top: 16px;
  position: absolute;
  margin-left: 4px;
}
.md_low{
  position: absolute;
  margin-left: 6px;
  margin-bottom: -155px;
  bottom: 0;
}
.chart{
  position: relative;
}
.chart_canvas{
  position: absolute;
  z-index: 1111111;
  margin: 0px 0px 0px 12px;
  width: 96.5%;
}
.box{
    z-index: 11111111;
    background: white;
    width: 100%;
    height: 23px;
    position: absolute;
}
</style>
