<template>
    <div class="question_list mt-6 mb-4">
        <div class="text-base line-height-27 b-d pl-4 pr-4 pb-2 b-d-c br-6">
            <AnswerList v-if="question.question_type.short_title == 'MCQMA' || question.question_type.short_title == 'MCQSA' || question.question_type.short_title == 'ROP'" :question_types="answer.question" :answer="answer.answer_options" :rop_student_pairs="answer.rop_student_pairs"  :activeValue="active_red"/>
            <DragDrop v-if="question.question_type.short_title == 'FGDND'" :case_item="question" :answer_drop="answer.answer_options"/>
            <DropDown v-if="question.question_type.short_title == 'FGDD'" :case_item="question" :answer_down="answer.answer_options" :active_green="active_green"/>
        </div>
        <div class="evaluation_header mt-6">
            <Alert background-color="#f0faef" classObject="rounded-lg p-2">
                <div class="pt-4 pl-4 pr-4" >  
                    <div class="flex justify-between align-center">
                        <div class="flex align-center">
                            <h3 style="margin-top: -3px;">Right Answer</h3>
                        </div>
                    </div>   
                    
                    <AnswerList v-if="question.question_type.short_title == 'MCQMA' || question.question_type.short_title == 'MCQSA' || question.question_type.short_title == 'ROP'" class="mb-4" :question="question.reorder_correct_pairs" :question_types="answer.question" :answer="question.question_options" :activeValue="active_green"/>
                    <span v-if="question.question_type.short_title == 'FGDND'" class="ck-content question__t answer" v-html="question.title">
                    </span>
                    <span v-if="question.question_type.short_title == 'FGDD'">
                        <DropDownAnswer :case_item="question" :answer_down="answer.question"/>
                    </span>
                </div>
                
            </Alert>
          
        </div>
    </div>
</template>
<script>
import AnswerList from "./answerList.vue";
import DropDown from "./question/DropDown/dropDown.vue";
import DropDownAnswer from "./question/DropDown/dropDown_question.vue";
import DragDrop from "./question/drag_drop.vue";
import { Alert} from '@/components';
export default {
    components: {
      Alert,
      AnswerList,
      DropDown,
      DragDrop,
      DropDownAnswer
    },
    props:{
        answer: {
           default: true 
        },
        question: {
           default: true 
        }
    },
    data() {
        return {
            question_type: [],
            disable: 1,
            active_red:'active_red',
            active_green:'active_green'
        }
    },
    mounted(){
       //this.PropsValue() 
    },
    
}
</script>
