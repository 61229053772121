<template>
    <div class="question_list mb-4 mt-2">
        <div class="evaluation_header" v-for="(explanations,index) in answer_sheet.question.explanations" :key="index" v-show="explanations.is_default === 1">
            <div class="flex justify-between align-center">
                <div class="flex align-center">
                    <h3 class="mb-2">Explanation</h3>
                </div>
            </div>
            <div>
                <Alert classObject="rounded-lg p-2 p-3  md-sl__font_600 md-sl__x1 mb-4 m-0 ">
                    <md-content >
                        <div class="question" v-html="explanations.title">
                        </div>
                    </md-content>
                    <md-content class="notes_body">
                        <div class="flex justify-between align-center">
                            <div class="flex align-center">
                                <h4 class="mb-1">Note</h4>
                            </div>
                        </div>
                        <div class="notes" v-html="explanations.notes">
                        </div>
                    </md-content>
                    <div class="md-layout">
                        <md-content class="md-layout-item md-size-30"  v-for="(material,index) in explanations.materials" :key="index">
                            <div class="mt-4 flex">
                                <div class="flex mt-2 align-center mr-8" v-if="material.file_type === 'IMAGE'">
                                    <ImageIcon/>
                                    <h4 class="ml-1">
                                        <a @click="swipe(material.file_url)"
                                            style="color: #000 !important;cursor:pointer"
                                           
                                            
                                            >{{material.file_path.split("/")[4].split(".")[0].replace(/(.{27})..+/, "$1&hellip;")}}.{{material.file_path.split("/")[4].split(".")[1]}}
                                        </a>
                                    </h4>
                                </div>
                                <div class="flex mt-2 align-center" v-if="material.file_type === 'AUDIO'">
                                    <AudioIcon/>
                                    <h4 class="ml-1">
                                        <a 
                                        style="color: #000 !important;cursor:pointer"
                                        target="_blank"
                                        :href="material.file_url"
                                        >{{material.file_path.split("/")[4].split(".")[0].replace(/(.{23})..+/, "$1&hellip;")}}.{{material.file_path.split("/")[4].split(".")[1]}}
                                        </a>
                                    </h4>
                                </div>
                            </div>
                            <div class="mt-1 flex">
                                <div class="flex mt-2 align-center mr-12" v-if="material.file_type === 'PDF' || material.file_type === 'DOCX'">
                                    <PDFIcon/>
                                    <h4 class="ml-1">
                                        <a 
                                            style="color: #000 !important;cursor:pointer"
                                            target="_blank"
                                            :href="material.file_url"
                                            >{{material.file_path.split("/")[4].split(".")[0].replace(/(.{23})..+/, "$1&hellip;")}}.{{material.file_path.split("/")[4].split(".")[1]}}
                                        </a>
                                    </h4>
                                </div>
                                <div class="flex mt-2 align-center" v-if="material.file_type === 'VIDEO'">
                                    <VideoIcon/>
                                        <h4 class="ml-1">
                                            <a 
                                            style="color: #000 !important;cursor:pointer"
                                            target="_blank"
                                            :href="material.file_url"
                                            > {{material.file_path.split("/")[4].split(".")[0].replace(/(.{23})..+/, "$1&hellip;")}}.{{material.file_path.split("/")[4].split(".")[1]}}
                                            </a>
                                        </h4>
                                </div>
                            </div>
                        </md-content>
                    </div>
                </Alert>
            </div>
        </div>
    </div>
</template>
<script>
import { Alert} from '@/components';
import AudioIcon from '../../../assets/svg/audioWav.svg';
//import VideoIcon from '../../../assets/svg/videoIcon.svg';
import ImageIcon from '../../../assets/svg/imageIcon.svg';
import PDFIcon from '../../../assets/svg/pdfIcon.svg';
import { mapGetters,mapActions} from "vuex";
export default {
    components: {
      Alert,
      AudioIcon,
      //VideoIcon,
      ImageIcon,
      PDFIcon
    },
    props:{
       answer_sheet: {
           default: true 
       }
    },
    data() {
        return {
           
        }
    },
    computed: {
      ...mapGetters({
        getExplanations: "explanation/getExplanations",
      }),
    },
   methods: {
    ...mapActions({
        actQuestionListExplanation: "explanation/actQuestionListExplanation",
    }),
    swipe(val) {
        window.open(val,'Image');
    }
  },
  created() {
    this.question_id = localStorage.getItem('question_id');
    this.actQuestionListExplanation(`?is_default=1&question_id=${this.question_id}`);
  }
}
</script>
