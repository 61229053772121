<template>
    <div>
        <div class="md-question__radio md-sl__font_600">
            <md-content style="background-color: #fff0" id="handleItem" class="p-3 br-3 md-sl__font_600 md-sl__x1 mb-4 m-0">
                <span v-for="(item, index) in  this.highlightWords" :key="index" class="answers" :id='"fill"+`${item.serial_no}`'>
                   {{item.title}}
                </span>
                <div class="answer_body">
                    <div id="dropDown" v-for="(item, index) in answer_drop" :key="index">  
                        <span  class="answer_section" :id="item.serial_no" v-bind:class="[item.is_wrong === 1 ? 'wrong_answers' : 'right_answers']"> 
                           {{item.title}}
                        </span>
                    </div>
                </div>
            </md-content>
        </div>
    </div>
</template>

<script>
export default {
    name: 'DropDown',
    data() {
      return {
          id: '',
          event: '',
          array:[],
          answer: [],
          highlightWords: [],
          serial_no: null,
          form: {
            answer_options: []
          }
      }
    },
    props: {
        case_item: {
           default: () => true
        },
        answer_down: {
           default: () => true
        },
        answer_drop:{
            default: () => true
        },
        active_green: {
            default: () => true
        }
    },
    mounted(){
        this.highlightWord();
        setTimeout(() => this.data(),500);
    },
    methods:{
        highlightWord(){
            const text = this.case_item.title.replace((/<span class="hidden">(.*?)<*span>/g), '')
            const texts = text.replace(/<[^>]+>/g, '')
            const Arrays = texts.split(" ");
            this.highlightWords = Arrays.map((item,index) => {
                return {
                title: item,
                serial_no: index+1
                }
            }); 
        },
        data(){
            const x = document.querySelectorAll('#dropDown span');
           
            for (let k = 0; k < x.length; k++) {
                const node = document.getElementById(x[k].id)
                document.getElementById("fill"+`${x[k].id}`).appendChild(node); 
                node.parentNode.className = "answers text_hide_color"
            } 
            
        }
    }
}
</script>
<style scoped>
.wrong_answers{
    color: red;
    position: absolute;
    left: 0;
    font-weight: 600;
}
.right_answers{
    color: #15be15;
    position: absolute;
    left: 1px;
    font-weight: 700;
}
.text_hide_color{
    color: #fff0;
    position: relative;
    padding: 0px 2px
}
</style>